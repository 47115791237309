<template lang="pug">
include ../../../configs/template
div.row.text-left
  div.col-xs-12.col-sm-12.col-md-6.col-lg-6
    +data-info('createDate', 'statement.created_at')
  div(v-if="statement.date_modified").col-xs-12.col-sm-12.col-md-6.col-lg-6
    +data-info('dateModified', 'statement.date_modified')
  div.col-xs-12.col-sm-12.col-md-6.col-lg-6
    +data-info('dateStartEdu', 'getDateFormat(statement.date_meeting)')
  div(v-if="statement.date_end_meeting").col-xs-12.col-sm-12.col-md-6.col-lg-6
    +data-info('dateEndEdu', 'getDateFormat(statement.date_end_meeting)')
  div.col-xs-12.col-sm-12.col-md-6.col-lg-6
    +data-info('qualification', 'getDirectoryObject({id: statement.level_qualification, value: "qualificationLevels"})[nameLang]')
  div.col-xs-12.col-sm-12.col-md-6.col-lg-6
    +data-info('nameInstitution', 'getDirectoryObject({ id: statement.educational_institution, value: "institution" })[nameLang]')
  div.col-xs-12.col-sm-12.col-md-6.col-lg-6
    +data-info('number', 'statement.number')
  div.col-xs-12.col-sm-12.col-md-6.col-lg-6
    +data-info('payment', 'statement.is_payed ? $t("isPayed") : $t("notPayed")')
  div.col-xs-12.col-sm-12.col-md-6.col-lg-6
    +data-info-status('status', 'getDirectoryObject({ id: statement.status_document, value: "statuses" })[nameLang]', 'getStatus(statement.status_document)')
  div(v-if="statement.comments?.length").col-sm-12
    h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
    v-divider
    div(v-for="(comment, index) in statement.comments" :key="comment.id").ml-4
      div
        +data-info('rejectionReasonStatement','getDirectoryObject({ id: comment.additional_info.reason, value: "rejectionReasons" })[nameLang]')
      div(v-if="checkAccess('backOffice')").pt-2.py-3
        +data-info('createdBy', 'comment.created_by.name')
      div.pt-2.py-3
        +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
      div.pt-2.py-3
        +data-info('reasonComment', 'comment.comment')
      v-divider(v-if="index !== statement.comments.length-1")
</template>

<script>
import { getStatus, getDateFormat } from '@/mixins/main'
import { mapGetters, mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'

export default {
  name: 'SeafarerGraduationApplicationInfo',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      checkAccess,
      getStatus,
      getDateFormat
    }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['getDirectoryObject'])
  }
}

</script>
